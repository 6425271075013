
@font-face {
    font-family: 'FuturaLTPro-Bold';
    src: url('FuturaLTPro-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura_lt_condensedboldRg';
    src: url('futuralt-condensedbold-webfont.woff2') format('woff2'),
         url('futuralt-condensedbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'shell_arial_bold';
    src: url('shellarialbold-webfont.woff2') format('woff2'),
         url('shellarialbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'shell_arial_regularregular';
    src: url('shellarialregular-webfont.woff2') format('woff2'),
         url('shellarialregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}